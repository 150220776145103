// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

ul.dropdown
  border-normal()
  z-index: $zi.nav
  right: 0
  position: absolute
  background: white
  padding: 0
  min-width: 14rem
  z-index: $zi.dropdown
  margin: 0

  &.larger
    li.dropdown-item
      & > a.button, & > button.button
        padding: $cs.l

  hr
    margin-top: 0
    height: .4rem

  li.dropdown-header-item
    border-normal('bottom')
    display: block
    margin-bottom: 0
    font-weight: $fw.bold

    span
      line-height: 1
      display: block
      padding: $cs.m

  li.dropdown-item
    display: block
    margin-bottom: 0

    button.button
      reset-button()

    & > a.button, & > button.button
      box-sizing: border-box
      line-height: 1
      display: block
      text-decoration: none
      padding: $cs.m
      width: 100%
      &:not(.active)
        color: $tc-deep-gray
        
      &.active
        border-left: $c-active-blue 5px solid
        color: $c-active-blue
        
      &:hover
        color: white
        
        .icon
          color: white

    .icon
      margin-right: $cs.xs

    &:not(:last-child)
      border-normal('bottom')

    &:hover
      background-color: $c-active-blue
